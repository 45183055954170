    <template>
        <v-app>
            <!--  style="background: #F4F6FA;" -->
            <NavBar style="max-height: 50px; margin-bottom: 10px" />
            <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                    <SideBar style="max-height: 50px" :pageName="pageName"/>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
                    <!-- <v-form ref="entryForm" @submit.prevent="goToAddAddressHandler"> -->
                    <!-- <div v-if="addUserView" class="scrollDiv"> -->
                        <div class="flexRowBetween my-6" style="height: 48px;">
                        <!-- border: 1px solid #E0E0E0; -->
                            <span class="commonHomeFont">
                                User Management
                            </span>
                        </div>
                        <!-- <div style="height: 64px"></div> -->
                        <div class="addNewUserInnerClass">
                            <div>
                                <span class="commonHomeFont">
                                    User Details
                                </span>
                            </div>
                            <div class="dashedLineClassInNewUser"></div>
                            <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                <div style="width:49%;">
                                    <div class="mb-2" style="text-align: left">Full Name<span class="astreikClass">*</span></div>
                                    <div>
                                        <v-text-field 
                                        solo flat
                                        background-color="#F4F6FA" 
                                        v-model="fullName" 
                                        :error-messages="v$.$error && v$.$errors[0].$property === 'fullName' ? v$.$errors[0].$message: ''"
                                        placeholder="Full Name" />
                                    </div>
                                        <!-- :rules="fullNameRules"  -->
                                </div>
                                <div style="width:49%;">
                                    <div class="mb-2" style="text-align: left">Phone Number<span class="astreikClass">*</span></div>
                                    <div class="MobileNumberClass">
                                        <div class="ml-2">
                                            <img src="https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg" align="left" style="width:26px ;height:26px;" />
                                        </div>
                                        <div class="ml-2">+95</div>
                                        <div class="ml-2">
                                            <v-text-field 
                                            disabled
                                            background-color="#F4F6FA" 
                                            v-model="mobileNumber" 
                                            :error-messages="v$.$error && v$.$errors[0].$property === 'mobileNumber'? v$.$errors[0].$message: ''"
                                            placeholder="09*********" />
                                            <!-- :rules="mobileNumberRules"  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column" style="width:100%; justify-content: space-between;">
                                <div class="mb-2" style="text-align: left">Email<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="emailID"
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'emailID'? v$.$errors[0].$message: ''"
                                    placeholder="Email ID" />
                                </div>
                            </div>
                            <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                <div style="width:49%;">
                                    <div class="mb-2" style="text-align: left">Gender<span class="astreikClass">*</span></div>
                                    <div>
                                        <v-text-field 
                                        solo flat
                                        background-color="#F4F6FA" 
                                        v-model="gender" 
                                        :error-messages="v$.$error && v$.$errors[0].$property === 'gender'? v$.$errors[0].$message: ''"
                                        placeholder="Gender" />
                                        <!-- :rules="fullNameRules"  -->
                                    </div>
                                </div>
                                <div style="width:49%;">
                                    <div class="mb-2" style="text-align: left">Date Of Birth<span class="astreikClass">*</span></div>
                                    <div>
                                        <v-menu min-width="auto" v-model="dateOfBirthMenu" ref="dateOfBirthMenu" offset-y transition="scale-transition" :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field 
                                                    v-model="dateOfBirth" class="rounded-lg" style="border-color: #E0E0E0;" 
                                                    outlined
                                                    placeholder="Select Date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :error-messages="v$.$error && v$.$errors[0].$property === 'dateOfBirth'? v$.$errors[0].$message: ''"
                                                    required
                                                    background-color="#F4F6FA"
                                                />
                                            </template>
                                            <v-date-picker v-model="dateOfBirth" required @change="dateOfBirthMenu = false" 
                                            />
                                        </v-menu>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column" style="width:100%; justify-content: space-between;">
                                <div class="mb-2" style="text-align: left">NRC<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'nrcModel'? v$.$errors[0].$message: ''"
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="nrcModel" 
                                    placeholder="N.R.C." />
                                </div>
                            </div>
                            <div class="d-flex flex-row" style="width:100%;">
                                <div class="d-flex flex-row" style="width:100%; justify-content: flex-start; align-items: center">
                                    
                                </div>
                                <div class="d-flex flex-row" style="width:50%; justify-content: space-between;">
                                    <div class="d-flex flex-row " @click="retryReset()" style="width:49%;"><!-- addNewAddressClass -->
                                        <!-- <span class="addNewAddressFontClass">
                                            Add New Address
                                        </span> -->
                                    </div>
                                    <div class="createNewUserClass" @click="goToAddAddress()" style="width:49%;cursor: pointer;">
                                        <span class="createNewUserFontClass">
                                            Save & Continue
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="my-4" style="width:99%; max-height: 200px;">
                            <OTPComponent :fromCreateUserPage="fromCreateUserPage"/>
                        </div>

                        <div v-if="confirmationFlag">
                            <DeleteConfirmationDialog :confirmationFlag="confirmationFlag" :itemType="itemType" @closeDialog="closeDialog" />
                        </div>
                        <div v-if="showMessageDialog">
                            <SuccessDialog :successObject="successObject" @closeSuccessDialog="closeSuccessDialog"/>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </v-app>
    </template>

    <script>
    // import axios from "axios";
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, numeric, minLength, maxLength } from '@vuelidate/validators';
    import { date_format, withParams } from 'vuelidate/lib/validators';
    import NavBar from '../../navbar.vue';
    import SideBar from '../../sideBar.vue';
    import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog.vue";
    import SuccessDialog from "../../../components/SuccessDialog.vue";
    import OTPComponent from '../../../components/OTPComp.vue';
    export default {
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    name: "CreateNewUserPage",
    components : {
        'NavBar': NavBar,
        'SideBar': SideBar,
        'DeleteConfirmationDialog': DeleteConfirmationDialog,
        'SuccessDialog': SuccessDialog,
        'OTPComponent': OTPComponent,
    },
    watch: {},
    computed: {
    },
    data() {
        return {
            gender: '',
            addUserView: false,
            addAddressView: false,
            pageName: 'CreateNewUserPage',
            fromCreateUserPage: '',
            fullName: '',
            nrcModel: '',
            emailID: '',
            dateOfBirthMenu: '',
            dateOfBirth: '',
            fullNameRules: [],
            mobileNumberRules: [
                v => !!v || 'Mobile Number is required',
                v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
                v => v > 0 || 'Mobile number cannot be negative',
            ],
            successObject: {},
            showMessageDialog: false,
            itemType:'',
            iconObjects: [],
            segmentCacheData: [],
            confirmationFlag: false,
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            editIcon: 'editIcon.svg',
            deleteIcon: 'deleteIcon.svg',
            userExistIcon: 'userExist.svg',
            noUserExistIcon: 'noUserExist.svg',
            retryGrey: 'retryGrey.svg',
            noSegmentImage: 'noSegment.svg',
            addBanner: 'addBanner.svg',
            finalEditFlag: false,
            editHeroBannerDialogFlag: false,
            finalEditValue: {},
            multipleAdds: 'multipleAdds.svg',
            addBlock: 'addBlock.svg',
            previewBanner: 'previewBanner.svg',
            addArticles: 'addArticles.svg',
            isHeroBannerAvailable: false,
            isSegmentAvailable: true,
            editFlag: false,
            showPromotionBanners: false,
            showBlock: false,
            showArticles: false,
            bannerTypes: ['Carousal', 'Single'],
            bannerType: '',
            headerColor: `#F4F6FA !important`,
            heroBannerDetails: [],
            customeSlotName: [],
            mobileNumber: '',
            userExistMessageFlag: false,
            userExistFlag: false,
            userData: {}
        }
    },
    validations () {
        return {
            fullName: { required },
            mobileNumber: { required, numeric, minLength: minLength(7), maxLength:maxLength(12) },
            emailID: {required, email},
            gender: { required },
            nrcModel: { required },
            dateOfBirth: { required }, 
        }
    },
    methods: {
        saveAndSendOTP() {

        },
        addNewAddress() {},
        async goToAddAddress() {
            const validateValie = await this.v$.$validate();
            if(validateValie) {
                this.userData['customerName'] = this.fullName;
                this.userData['phoneNumber'] = this.mobileNumber;
                this.userData['dob'] = this.dateOfBirth;
                this.userData['gender'] = this.gender;
                this.userData['nrc'] = this.nrcModel;
                this.userData['email'] = this.emailID;
                this.$router.push({
                    name: 'CreateAddressForNewUser',
                    query: {
                        params: this.userData
                    }
                })
            }
        },
        closeSuccessDialog() {
            this.showMessageDialog = false;
        },
        discardAddBlockDialog() {
            this.showPromotionBanners = false;
            this.showArticles = false;
            this.showBlock = false;
            this.isSegmentAvailable = false;
            this.showCommonDraggableIcon = false;
        },
        refreshPage() {
            window.location.reload();
        },
        closeDialog (valueFromEmit) {
            if (valueFromEmit.value) {
            } else {
                this.confirmationFlag = false;
            }
        },
    },
    async mounted() {
        this.addUserView = true;
        this.mobileNumber = this.$route.query.params;
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
            name: "AdminLogin",
            });
        } else {
            this.userExistIcon = this.cloudFront + this.userExistIcon;
            this.noUserExistIcon = this.cloudFront + this.noUserExistIcon;
            this.previewBanner = this.cloudFront + this.previewBanner;
            this.retryGrey = this.cloudFront + this.retryGrey;
            this.noSegmentImage = this.cloudFront + this.noSegmentImage;
            this.multipleAdds = this.cloudFront + this.multipleAdds;
            this.addBanner = this.cloudFront + this.addBanner;
            this.addBlock = this.cloudFront + this.addBlock;
            this.addArticles = this.cloudFront + this.addArticles;
            this.draggableIcon =  this.cloudFront + this.draggableIcon;
            this.editIcon = this.cloudFront + this.editIcon;
            this.deleteIcon = this.cloudFront + this.deleteIcon;
        }
    },
    }
    </script>

    <style>

    .scrollDiv {
        background: var(--grays-blue-gray-6, #F4F6FA);
        max-height: 1100px;
        overflow-y: scroll;
    }
    .draggable-item {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    cursor: grab;
    width: 100%;
    }

    .flexRowBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
    .commonHomeFont{
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
    }
    .addNewUserInnerClass {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 32px;
        isolation: isolate;
        width: 99%;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        border-radius: 12px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
    }
    .dashedLineClassInNewUser {
    height: 0px;
    border: 1px dashed #BDBDBD;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    }
    .ValidateUserClass {
        display: flex;
        width: 240px;
        height: 48px;
        padding: 8px 56px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #219653;
    }
    .ValidateUserFontClass {
        color: var(--main-color-white, #FFF);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 180%;
    }
    .UserValidationFontClass{
        color: var(--grays-gray-1, #333);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .MobileNumberClass {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 12px 13px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: var(--grays-blue-gray-6, #F4F6FA);
    }
    .noUserExistClass{
        color: var(--secondary, #EB5757);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .userExistClass {
        color: var(--success-green, #34C873);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .addNewAddressClass {
        display: flex;
        height: 48px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 4px;
        border: 1px solid var(--grays-gray-4, #BDBDBD);
    }
    .addNewAddressFontClass {
        color: var(--grays-grey-3, #828282);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 180%;
    }
    .createNewUserClass {
        display: flex;
        width: 186px;
        height: 48px;
        padding: 8px 56px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 4px;
        background: #219653;
    }
    .createNewUserFontClass {
        color: var(--main-color-white, #FFF);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 180%;
    }
    .astreikClass {
        color: var(--secondary, #EB5757);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    </style>